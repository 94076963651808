import * as React from 'react';
import { useSignMessage, useAccount } from 'wagmi';
import { recoverMessageAddress } from 'viem';
import { apiUrl } from '../utils/constants.ts';

export function SignMessage({ sender, content, timestamp }) {
  const { data: signMessageData, isLoading, signMessage, variables, reset } = useSignMessage();
  const { address } = useAccount();

  // Extract ShitlordAddress from the URL
  const ShitlordAddress = React.useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('address') || '';
  }, []);

  React.useEffect(() => {
    (async () => {
      if (variables?.message && signMessageData) {
        const recoveredAddress = await recoverMessageAddress({
          message: variables?.message,
          signature: signMessageData,
        });
        if (recoveredAddress) {
          const sendData = {
            ShitlordAddress, // Extracted from URL
            sender,
            content: variables?.message,
            timestamp,
          };
          console.log('Payload being sent:', sendData); // Debug log
          const response = await fetch(apiUrl + '/api/add', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
            },
            redirect: 'error',
            body: JSON.stringify(sendData),
          });
          reset();
          document.getElementById('message').value = '';
          if (response.status !== 200) {
            const { error } = await response.json();
            throw new Error(error);
          }
        }
      }
    })();
  }, [signMessageData, variables?.message]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const message = formData.get('message');
        signMessage({ message });
      }}
    >
      <div className="TextAreaContainer">
        <textarea
          style={{ width: '-webkit-fill-available' }}
          rows={6}
          id="message"
          name="message"
          placeholder="Type your message here"
          className="rounded-[25px] p-6 text-white bg-[#192d1c]"
        />
      </div>
      <button disabled={isLoading || address === undefined} className="SendButton rounded-full text-[#222] py-2">
        {address === undefined ? 'Connect Wallet First' : isLoading ? 'Check Wallet' : 'Send Message'}
      </button>
    </form>
  );
}
